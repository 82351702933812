/* eslint-disable react-hooks/exhaustive-deps */
import { Component } from 'react';
import { oneofficeAppURI, onroadAppURI, webfleetURL } from '../config';
import authFetchWrapper from '../api/authFetchWrapper';
import { useHeaders } from '../hooks/useHeaders';
import { othersNearBy } from '../utils/GeoUtil';
import { format } from 'date-fns';
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import { convertUTCDate } from '../utils/DateUtil';

const colorsForItems = [
  '#FFFF00',
  '#FFA500',
  '#d08ff6',
  '#FFC0CB',
  '#00FF00',
  '#C0C0C0',
  '#FF6666',
  '#FFB266',
  '#FFFF66',
  '#B2FF66',
  '#66FF66',
  '#66FFB2',
  '#66FFFF',
  '#66B2FF',
  '#6666FF',
  '#B266FF',
  '#FF66FF',
  '#FF66B2',
  '#FF9999',
  '#FFCC99',
  '#FFFF99',
  '#CCFF99',
  '#99FF99',
  '#99FFCC',
  '#99FFFF',
  '#99CCFF',
  '#9999FF',
  '#CC99FF',
  '#FF99FF',
  '#FFCCCC',
  '#FFE5CC',
  '#FFFFCC',
  '#E5FFCC',
  '#CCFFCC',
  '#CCFFE5',
  '#CCFFFF',
  '#CCE5FF',
  '#CCCCFF',
];

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: [],
      filteredDrivers: [],
      filterActive: false,
      city: undefined,
      filter: {},
      countdown: 0,
      lastBounds: undefined,
      webfleetPositions: [],
      shipPosition: undefined,
      shipPosition2: undefined,
    };
  }

  _map = undefined;
  _markers = [];
  _vehicleMarkers = [];
  _shipMarkers = [];

  loaderInterval = null;
  countdownInterval = null;

  updateCountdown = () => {
    this.setState({
      ...this.state,
      countdown: this.state.countdown > 1 ? this.state.countdown - 1 : 120,
    });
  };

  startCountdown = () => {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
    this.countdownInterval = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  };

  forceLoading = () => {
    this.setState(
      {
        ...this.state,
        countdown: 120,
      },
      () => {
        this.loadMapData(true);
        this.startCountdown();
        if (this.loaderInterval) {
          clearInterval(this.loaderInterval);
          this.loaderInterval = null;
        }
        this.loaderInterval = setInterval(() => {
          //load map data
          this.setState(
            {
              ...this.state,
              countdown: 120,
            },
            () => {
              this.loadMapData();
              this.startCountdown();
            }
          );
        }, 120000);
      }
    );
  };

  clearLoader = () => {
    if (this.loaderInterval) {
      clearInterval(this.loaderInterval);
      this.loaderInterval = null;
    }
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
    }
  };

  setCityCenter = async () => {
    const { city } = this.state;
    if (!city?.length) {
      return;
    }
    const res = await fetch(
      `https://gorsele-maps.herokuapp.com/api/maps/geocode?city=${city}`
    );
    const data = await res.json();
    if (data?.coordinates) {
      this.updateMap(this.state.drivers, {
        lat: data.coordinates[1],
        lng: data.coordinates[0],
      });
    }
  };

  buildMarkerContent = (driver, isVehicle, isShip) => {
    const content = document.createElement('div');

    content.classList.add('property');
    content.innerHTML = isShip
      ? `<div class="marker markerveh">
    <div class="title titleveh ship"><img src="/img/ship.png" width="20" height="12" alt="${driver.name}"/>
      <div class="spacer spacership"></div>
      <span>${driver.name}</span></div>
  </div>`
      : isVehicle
      ? `<div class="marker markerveh">
            <div class="title titleveh"><img src="/img/${
              (driver.seats && driver.seats === '?') ||
              parseInt(driver.seats || 0) <= 3
                ? 'car'
                : 'bus'
            }.png" width="20" height="8" alt="${driver.licensePlate}"/>
            <div class="spacer"></div>
            <span style="display:inline-block">${
              driver.seats && driver.seats !== '?'
                ? `<img src="/img/${
                    parseInt(driver.seats) <= 3 ? 'car' : 'bus'
                  }.png" width="20" height="8" alt="${
                    driver.licensePlate
                  }" style="display:inline-block"/>`
                : ''
            } <img src="/img/car-bomb.png" width="16" height="6" alt="track" style="display:inline-block; width: 18px; height:12px;"/> ${
          driver.lisVehicleReserved
            ? '<img src="/img/switch.png" width="4px" height="4px" alt="switch" style="display:inline-block; width: 12px; height:12px;"/>'
            : ''
        } ${driver.licensePlate}(${driver.seats}p)</span></div>
          </div>`
      : `
        <div class="marker-details">
              <div class="driver">${driver.alias} </div>
              <div class="licenseplate">${driver.lastKnownLicensePlate}(${
          driver.lastKnowLicensePlateSeats
        }p)</div>
              <div class="model">${driver.vehicle?.model.fullModel} ${
          driver.vehicle?.model.category.name
        }</div>
              <div class="features">
                <div>
                    <i aria-hidden="true" class="fa fa-bed fa-lg bed" title="bedroom"></i>
                    <span class="fa-sr-only">Locatie tijd</span>
                    <span>${format(
                      driver.lastPosition.maxTime,
                      'dd/MM HH:mm'
                    )}</span>
                </div>
              </div>
          </div>
          <div class="marker">
                <div class="title" style="background-color:${
                  driver.color
                }"><span>${driver.alias} ${driver.lastKnownLicensePlate}${
          driver.lastKnownLicensePlate !== 'INGEVEN' &&
          driver.lastKnowLicensePlateSeats
            ? `(${driver.lastKnowLicensePlateSeats}p)`
            : ''
        }</span></div>
            <div class="pin">&nbsp;</div>
          </div>
          
          `;
    return content;
  };

  toggleMarkerHighlight(markerView, position) {
    if (markerView.content.classList.contains('highlight')) {
      markerView.content.classList.remove('highlight');
      markerView.zIndex = null;
    } else {
      markerView.content.classList.add('highlight');
      markerView.zIndex = 1;
    }
  }

  onFilterChange = (prop, val) => {
    let newFilter = { ...this.state.filter, [prop]: val };
    let filterActive =
      newFilter.licensePlate?.length ||
      newFilter.driver?.length ||
      newFilter.lisOpdrachtId?.length
        ? true
        : false;
    let newDrivers = JSON.parse(JSON.stringify(this.state.drivers));
    newDrivers = newDrivers.filter((driver) =>
      driver.lastKnownLicensePlate.match(
        new RegExp(`^${newFilter.licensePlate || ''}`, 'i')
      )
    );
    newDrivers = newDrivers.filter((driver) =>
      driver.alias.match(new RegExp(`^${newFilter.driver || ''}`, 'i'))
    );
    newDrivers = newDrivers.filter((driver) => {
      if (!driver.planning?.length) {
        if (newFilter.lisOpdrachtId?.length) {
          return false;
        }
        return true;
      } else {
        let driverPlanning = [];
        driver.planning.forEach((planItem) => {
          driverPlanning.push(planItem.lisData.LisOpdrachtId);
        });
        const driverPlanToString = driverPlanning.join(',');
        return driverPlanToString.match(
          new RegExp(`^${newFilter.lisOpdrachtId || ''}`, 'i')
        );
      }
    });
    if (filterActive) {
      if (newDrivers?.length !== this.state.filteredDrivers?.length) {
        this.updateMap(newDrivers, false, true);
      }
    } else {
      if (newDrivers?.length !== this.state.filteredDrivers?.length) {
        this.updateMap(this.state.drivers, false, true);
      }
    }
    this.setState({
      ...this.state,
      filter: newFilter,
      filteredDrivers: newDrivers,
      filterActive,
    });
  };

  updateMap = async (drivers, forcePosition, fitBound) => {
    const { webfleetPositions, shipPosition, shipPosition2 } = this.state;
    const google = window.google;
    const { Map } = await google.maps.importLibrary('maps');
    let coordinates = [];
    let markers = [];
    let vehicleMarkers = [];

    if (this._map && this._markers?.length) {
      for (let m = 0; m < this._markers.length; m++) {
        this._markers[m].setMap(null);
        this._markers[m] = null;
      }
      this._markers = [];
    }

    if (this._map && this._vehicleMarkers?.length) {
      for (let m = 0; m < this._vehicleMarkers.length; m++) {
        this._vehicleMarkers[m].setMap(null);
        this._vehicleMarkers[m] = null;
      }
      this._vehicleMarkers = [];
    }

    if (this._map && this._shipMarkers?.length) {
      for (let m = 0; m < this._vehicleMarkers.length; m++) {
        this._shipMarkers[m].setMap(null);
        this._shipMarkers[m] = null;
      }
      this._shipMarkers = [];
    }

    drivers.forEach((driver) => {
      if (driver.lastPosition) {
        const othersClose = othersNearBy(
          driver.lastPosition.maxGeometry.coordinates,
          coordinates
        );
        const addMeters = othersClose ? othersClose * 0.0005 : 0;
        const marker = {
          driver,
          position: {
            lat: driver.lastPosition.maxGeometry.coordinates[1] + addMeters,
            lng: driver.lastPosition.maxGeometry.coordinates[0] + addMeters,
          },
        };
        markers.push(marker);
        coordinates.push(driver.lastPosition.maxGeometry.coordinates);
      }
    });

    if (webfleetPositions?.length) {
      webfleetPositions?.forEach((wdPos) => {
        for (var d = 0; d < drivers.length; d++) {
          if (
            drivers[d].lastKnownLicensePlate &&
            drivers[d].lastKnownLicensePlate === wdPos.maxLicensePlate
          ) {
            const marker = {
              licensePlate: wdPos.maxLicensePlate,
              seats: drivers[d].lastKnowLicensePlateSeats || '?',
              position: {
                lat: wdPos.maxGeometry.coordinates[1],
                lng: wdPos.maxGeometry.coordinates[0],
              },
              lisVehicleReserved: wdPos.lisVehicleReserved || false,
            };
            vehicleMarkers.push(marker);
          }
        }
      });
    }

    if (!this._map) {
      this._map = new Map(document.getElementById('map'), {
        mapId: 'gorsele9120',
        center: forcePosition || { lat: 51.34557, lng: 4.2488 },
        zoom: forcePosition ? 12 : 8,
      });
      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(this._map);
      this._map.maxDefaultZoom = 16;
      google.maps.event.addListenerOnce(this._map, function () {
        this.setZoom(Math.min(this.getZoom(), this.maxDefaultZoom));
      });
    }

    if (this._map && forcePosition) {
      this._map.setCenter(forcePosition);
      this._map.setZoom(12);
    }

    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    var bounds = new google.maps.LatLngBounds();

    vehicleMarkers.forEach((vmarker) => {
      const markerElement = new AdvancedMarkerElement({
        map: this._map,
        content: this.buildMarkerContent(vmarker, true),
        position: vmarker.position,
        title: vmarker.licensePlate,
      });
      this._vehicleMarkers.push(markerElement);
      bounds.extend(vmarker.position);
    });

    if (shipPosition) {
      const markerElement = new AdvancedMarkerElement({
        map: this._map,
        content: this.buildMarkerContent({ name: 'RAVELINGEN' }, false, true),
        position: {
          lat: parseFloat(shipPosition.coordinates[1]),
          lng: parseFloat(shipPosition.coordinates[0]),
        },
        title: 'RAVELINGEN',
      });
      this._shipMarkers.push(markerElement);
    }

    if (shipPosition2) {
      const markerElement = new AdvancedMarkerElement({
        map: this._map,
        content: this.buildMarkerContent({ name: 'ZEEASTER' }, false, true),
        position: {
          lat: parseFloat(shipPosition2.coordinates[1]),
          lng: parseFloat(shipPosition2.coordinates[0]),
        },
        title: 'ZEEASTER',
      });
      this._shipMarkers.push(markerElement);
    }

    markers.forEach((marker) => {
      const markerElement = new AdvancedMarkerElement({
        map: this._map,
        content: this.buildMarkerContent(marker.driver),
        position: marker.position,
        title: marker.alias,
      });
      markerElement.addListener('click', () => {
        this.toggleMarkerHighlight(markerElement, marker);
      });
      this._markers.push(markerElement);
      bounds.extend(marker.position);
    });

    if (!forcePosition && markers?.length && fitBound) {
      this._map.fitBounds(bounds);
      this._map.setZoom(
        Math.min(this._map.getZoom(), this._map.maxDefaultZoom)
      );
    }
  };

  createStandByTimeUTC = (execDateUTC, firstPickupTime) => {
    const pParts = firstPickupTime.split(' - ');
    const startTime = pParts.length > 1 ? pParts[0] : null;
    const endTime = pParts.length > 1 ? pParts[1] : null;
    let from = null;
    let to = null;
    if (startTime && endTime) {
      const startParts = startTime.split(':');
      const endParts = endTime.split(':');
      let startHour = undefined;
      let startMinutes = undefined;
      let endHour = undefined;
      let endMinutes = undefined;
      if (startParts.length > 1) {
        startHour = parseInt(startParts[0]);
        startMinutes = parseInt(startParts[1]);
      }
      if (endParts.length > 1) {
        endHour = parseInt(endParts[0]);
        endMinutes = parseInt(endParts[1]);
      }
      if (
        startHour !== undefined &&
        startMinutes !== undefined &&
        endHour !== undefined &&
        endMinutes !== undefined
      ) {
        from =
          convertUTCDate(new Date(execDateUTC), startHour, startMinutes, 0) -
          3 * 60 * 60 * 1000;
        to =
          convertUTCDate(new Date(execDateUTC), endHour, endMinutes, 0) +
          2 * 60 * 60 * 1000;
      }
    }
    return { from, to };
  };

  createPickupTimeDate = (execDate, routeItem, end) => {
    var utcPickup = null;
    if (!routeItem.noSpecificTime) {
      if (!routeItem.isRange) {
        utcPickup = convertUTCDate(
          execDate,
          parseInt(routeItem.pickupHour),
          parseInt(routeItem.pickupMinutes),
          0
        );
      } else {
        if (routeItem.overNight) {
          utcPickup = convertUTCDate(
            end ? new Date(execDate.getTime() + 24 * 60 * 60 * 1000) : execDate,
            parseInt(end ? routeItem.endHour : routeItem.pickupHour),
            parseInt(end ? routeItem.endMinutes : routeItem.pickupMinutes),
            0
          );
        } else {
          utcPickup = convertUTCDate(
            execDate,
            parseInt(end ? routeItem.toPickupHour : routeItem.fromPickupHour),
            parseInt(
              end ? routeItem.toPickupMinutes : routeItem.fromPickupMinutes
            ),
            0
          );
        }
      }
      return utcPickup;
    } else {
      utcPickup = convertUTCDate(execDate, 0, 0, 0);
      return utcPickup;
    }
  };

  createDrivers = (standby, planning, positions) => {
    let drivers = [];
    let driverIndex = 0;
    standby.forEach((standbyOrder) => {
      if (standbyOrder.loodswezenStandBy) {
        const rangeStart = this.createPickupTimeDate(
          new Date(standbyOrder.execDateUTC),
          standbyOrder.route.places[0]
        );
        const rangeEnd = this.createPickupTimeDate(
          new Date(standbyOrder.execDateUTC),
          standbyOrder.route.places[0],
          true
        );
        const fromToUTC = {
          from: rangeStart,
          to: rangeEnd,
        };
        let found = false;
        for (var d = 0; d < drivers.length; d++) {
          if (drivers[d]._id === standbyOrder.driver._id) {
            found = true;
            //changed 8 to 2 hours
            if (
              fromToUTC.from &&
              fromToUTC.to &&
              fromToUTC.from < Date.now() + 2 * 60 * 60 * 1000 &&
              fromToUTC.to > Date.now()
            ) {
              drivers[d].standByTimes.push({
                execDateUTC: standbyOrder.execDateUTC,
                time: standbyOrder.route.places[0].pickupTime,
                fromUTC: fromToUTC.from,
                toUTC: fromToUTC.to,
                driverStarted: standbyOrder.driverStarted || null,
                startlocation: standbyOrder.startlocation || null,
              });
              drivers[d].lisVehicleReserved = standbyOrder.lisVehicleReserved
                ? true
                : drivers[d].lisVehicleReserved;
            }
          }
        }
        if (!found) {
          //changed 8 to 2 hours
          if (
            fromToUTC.from &&
            fromToUTC.to &&
            fromToUTC.from < Date.now() + 2 * 60 * 60 * 1000 &&
            fromToUTC.to > Date.now()
          ) {
            drivers.push({
              ...standbyOrder.driver,
              color: colorsForItems[driverIndex],
              vehicle: standbyOrder.vehicle,
              lastKnownLicensePlate: standbyOrder.vehicle.licensePlate,
              lastKnowLicensePlateSeats:
                standbyOrder.vehicle?.model?.category?.seats || '?',
              lisVehicleReserved: standbyOrder.lisVehicleReserved
                ? true
                : false,
              standByTimes: [
                {
                  execDateUTC: standbyOrder.execDateUTC,
                  time: standbyOrder.route.places[0].pickupTime,
                  fromUTC: fromToUTC.from,
                  toUTC: fromToUTC.to,
                  driverStarted: standbyOrder.driverStarted || null,
                  startlocation: standbyOrder.startlocation || null,
                },
              ],
            });
          }
          driverIndex++;
        }
      } else {
        let foundDriverOrder = false;
        for (var dd = 0; dd < drivers.length; dd++) {
          if (drivers[dd]._id === standbyOrder.driver._id) {
            foundDriverOrder = true;
          }
        }
        if (!foundDriverOrder) {
          //15u00
          if (
            standbyOrder.route?.firstPickupTime &&
            standbyOrder.route?.firstPickupTime <
              Date.now() + 4 * 60 * 60 * 1000 &&
            standbyOrder.route?.firstPickupTime + 2 * 60 * 60 * 1000 >
              Date.now()
          ) {
            drivers.push({
              ...standbyOrder.driver,
              color: colorsForItems[driverIndex],
              ordernr: standbyOrder.ordernr,
              vehicle: standbyOrder.vehicle,
              lastKnownLicensePlate: standbyOrder.vehicle.licensePlate,
              standByTimes: [],
            });
            driverIndex++;
          }
        }
      }
    });
    planning.forEach((planItem) => {
      for (var d = 0; d < drivers.length; d++) {
        if (drivers[d]._id === planItem.driver?._id) {
          drivers[d].planning = drivers[d].planning ? drivers[d].planning : [];
          drivers[d].planning.push({
            ordernr: planItem.ordernr,
            lisData: planItem.lisData || null,
            gorselePlanned: planItem.lisData ? false : true,
            executed: planItem.executed,
            route: planItem.route?.routeString?.short || '',
          });
        }
      }
    });
    positions.forEach((position) => {
      for (var d = 0; d < drivers.length; d++) {
        if (drivers[d]._id === position._id) {
          drivers[d].lastPosition = position;
          /*if (position.maxLicensePlate) {
            drivers[d].lastKnownLicensePlate =
              position.maxLicensePlate?.length > 7
                ? drivers[d].lastKnownLicensePlate
                : position.maxLicensePlate.substring(
                    0,
                    position.maxLicensePlate?.length < 7
                      ? position.maxLicensePlate
                      : 7
                  );
          }*/
        }
      }
    });
    return drivers;
  };

  loadMapData = async (first) => {
    const res = await authFetchWrapper(`${onroadAppURI}protected/map`, {
      method: 'GET',
      headers: this.props.headers,
    });
    const data = await res.json();
    if (!data?.success) {
      return;
    }

    //load webfleet data
    const webfleetRes = await fetch(`${oneofficeAppURI}tomtomtracks`);
    const webfleetData = await webfleetRes.json();

    let shipPosition = null;
    let shipPosition2 = null;
    const shipRes = await authFetchWrapper(`${oneofficeAppURI}shipdata`, {
      method: 'GET',
      headers: this.props.headers,
    });
    const shipData = await shipRes.json();
    const ravelingenMMSI = 205598000;
    const zeeasterMMSI = 205665000;
    if (shipData?.success && shipData?.shipPositions?.length) {
      shipData.shipPositions.forEach((shipPos) => {
        if (shipPos.mmsi === ravelingenMMSI) {
          shipPosition = shipPos.geometry;
        }
        if (shipPos.mmsi === zeeasterMMSI) {
          shipPosition2 = shipPos.geometry;
        }
      });
    }

    let webfleetPositions = [];
    if (webfleetData?.locations?.length && data?.standby?.length) {
      for (let wd = 0; wd < webfleetData.locations.length; wd++) {
        let wdPos = webfleetData.locations[wd];
        const wdLic = wdPos['objectno'].trim().toUpperCase();
        if (wdLic.indexOf('ZZ') === -1) {
          webfleetPositions.push({
            maxLicensePlate: wdLic,
            maxGeometry: wdPos.geometry,
            maxAddress: wdPos.positionText,
            maxTime: wdPos.positionTime,
          });
        }
      }
    }

    let drivers = this.createDrivers(
      data.standby,
      data.planning,
      data.positions
    );
    for (let d = 0; d < drivers.length; d++) {
      for (let wp = 0; wp < webfleetPositions.length; wp++) {
        if (
          webfleetPositions[wp].maxLicensePlate ===
          drivers[d].lastKnownLicensePlate
        ) {
          drivers[d].hasTrack = true;
          if (drivers[d].lisVehicleReserved) {
            webfleetPositions[wp].lisVehicleReserved = true;
          }
        }
      }
    }
    this.setState(
      {
        ...this.state,
        drivers,
        filteredDrivers: drivers,
        webfleetPositions,
        shipPosition,
        shipPosition2,
      },
      () => {
        this.updateMap(drivers, false, first || false);
      }
    );
  };

  resetFilter = () => {
    this.setState(
      {
        ...this.state,
        filter: {},
        filterActive: false,
        filteredDrivers: this.state.drivers,
        city: undefined,
      },
      () => {
        this.updateMap(this.state.drivers, false, true);
      }
    );
  };

  componentDidMount = () => {
    this.forceLoading();
  };

  componentWillUnmount = () => {
    this.clearLoader();
  };

  render() {
    const { filterActive, filteredDrivers, drivers, countdown, city, filter } =
      this.state;
    let returnDrivers = filterActive ? filteredDrivers : drivers;

    return (
      <div className="flex flex-row bg-red h-full">
        <div className="w-full relative" style={{ height: '100%' }}>
          <div className="absolute top-2 right-14 z-[992] w-[200px] flex justify-end">
            <div className="text-black bg-yellow-500 font-bold text-xs p-2 rounded-md border-2 border-yellow-600 mr-2">
              Herlaad in {countdown}s
            </div>
            <button
              className="text-white text-xs bg-gray-500 w-16 font-bold rounded-md mr-2 p-2 border-2 border-gray-700"
              onClick={() => this.resetFilter()}
            >
              Reset
            </button>
            {filterActive ? (
              <span className="p-2 rounded-md right-0 bg-white border-green-500 border-2 text-xs font-bold">
                Filter actief
              </span>
            ) : null}
          </div>
          <div id="map" className="w-full" style={{ height: '100%' }} />
        </div>
        <div className="w-60 flex flex-col text-sm overflow-scroll overflow-x-hidden h-[calc(100vh-60px)]">
          <div className="p-2 text-xs">
            {returnDrivers.map((driver, d) => (
              <div
                key={d}
                className="border-2 rounded-md mb-2 hover:bg-gray-200 hover:cursor-pointer"
                onClick={() => this.updateMap([driver], false, true)}
              >
                <div
                  className="w-full p-1"
                  style={{ backgroundColor: driver.color }}
                >
                  <div className="flex flex-row">
                    {driver.lastKnownLicensePlate !== 'INGEVEN' &&
                    driver.lastKnowLicensePlateSeats !== '?' ? (
                      <img
                        src={`/img/${
                          parseInt(driver.lastKnowLicensePlateSeats) <= 3
                            ? 'car'
                            : 'bus'
                        }.png`}
                        width="auto"
                        height="12"
                        alt={driver.lastKnownLicensePlate}
                        className="h-[12px] w-auto"
                      />
                    ) : null}
                    {driver.hasTrack ? (
                      <>
                        <img
                          src={`/img/car-bomb.png`}
                          width="auto"
                          height="12"
                          alt="track"
                          className="ml-2 h-[12px] w-auto"
                        />
                        <span className="text-[9px] font-bold ml-2">
                          (TRACK)
                        </span>
                      </>
                    ) : null}
                    {driver.lisVehicleReserved ? (
                      <>
                        <img
                          src={`/img/switch.png`}
                          width="auto"
                          height="12"
                          alt="track"
                          className="ml-2 h-[12px] w-auto"
                        />
                      </>
                    ) : null}
                  </div>
                  {driver.lastKnownLicensePlate}
                  {driver.lastKnownLicensePlate !== 'INGEVEN' ? (
                    <span>({driver.lastKnowLicensePlateSeats}p)</span>
                  ) : null}{' '}
                  - {driver.alias} {driver.ordernr}
                  {driver.gsmWork?.length ? (
                    <span className="flex">
                      <DevicePhoneMobileIcon className="w-4 h-4" />
                      {driver.gsmWork}
                    </span>
                  ) : null}
                </div>
                <div className="p-1">
                  {driver.planning?.length ? (
                    <div>
                      <p className="font-bold">Planning</p>
                      {driver.planning.map((planItem, p) => (
                        <div key={p}>
                          {planItem.lisData ? (
                            <p className="font-bold text-green-400">
                              Opdracht {planItem.lisData.LisOpdrachtId}
                            </p>
                          ) : null}
                          {planItem.gorselePlanned ? (
                            <p className="font-bold text-green-400">
                              Gorselé gepland {planItem.ordernr}
                            </p>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-red-500 font-bold">Geen planning</p>
                  )}
                  {driver.standByTimes?.length ? (
                    <>
                      <p className="font-bold">Stand-by tijd</p>
                      <ul>
                        {driver.standByTimes.map((sbTime, s) => {
                          return (
                            <li key={s}>
                              {format(sbTime.execDateUTC, 'dd/MM')}{' '}
                              {sbTime.time}
                              {sbTime.driverStarted ? (
                                <span>
                                  <br />
                                  Gestart{' '}
                                  {format(sbTime.driverStarted, 'HH:mm')}
                                  {sbTime.startlocation
                                    ? ` te ${sbTime.startlocation}`
                                    : ''}
                                </span>
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <p className="font-bold text-red-500">
                      Chauffeur niet stand-by
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-60 text-sm">
          <div className="p-4 bg-gray-400">
            <h1 className="font-bold mb-2">Map centreren op</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.setCityCenter();
              }}
            >
              <input
                id="city"
                name="city"
                placeholder="1000 brussel"
                value={city || ''}
                className="appinput"
                required
                onChange={(e) => this.setState({ city: e.currentTarget.value })}
              />
            </form>
            <input
              className="w-full bg-primary-300 rounded-sm p-1"
              type="submit"
              value="Centreer"
            />
          </div>
          <div className="p-4 h-full bg-gray-200">
            <h1 className="font-bold mb-2">Zoeken</h1>
            <label className="text-sm">Nummerplaat</label>
            <input
              id="licenseplate"
              name="licenseplate"
              value={filter.licensePlate || ''}
              className="appinput"
              onChange={(e) =>
                this.onFilterChange(
                  'licensePlate',
                  e.currentTarget.value.toUpperCase()
                )
              }
            />
            <label className="text-sm">Chauffeur</label>
            <input
              id="driver"
              name="driver"
              value={filter.driver || ''}
              className="appinput"
              onChange={(e) =>
                this.onFilterChange(
                  'driver',
                  e.currentTarget.value.toLowerCase()
                )
              }
            />
            <label className="text-sm">Lis Opdracht ID</label>
            <input
              id="lisorder"
              name="lisorder"
              className="appinput"
              value={filter.lisOpdrachtId || ''}
              onChange={(e) =>
                this.onFilterChange('lisOpdrachtId', e.currentTarget.value)
              }
            />
            <button
              className="w-full bg-slate-300 rounded-sm p-1"
              onClick={() => this.resetFilter()}
            >
              Reset
            </button>
            <a
              className="block w-full bg-green-300 rounded-sm p-1 mt-2 text-center"
              href="https://www.google.com/maps/"
              target="_blank"
              rel="noreferrer"
            >
              Google Maps
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const injectHeaders = (Component) => {
  const InjectedHeaders = function (props) {
    const headers = useHeaders(true);
    return <Component {...props} headers={headers} />;
  };
  return InjectedHeaders;
};

export default injectHeaders(DashBoard);
