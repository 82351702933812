import { MapPinIcon } from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  TruckIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { createAddressForNavigation } from '../utils/GeoUtil';
import Swal from 'sweetalert2';
import { oneofficeAppURI } from '../config';
import toast from 'react-hot-toast';
import { useAuth } from '../hooks/useAuth';
import { getUserForToken } from '../token';
import { useEffect, useState } from 'react';

const PlanningList = ({ planning, inverse }) => {
  const { jwt } = useAuth();
  const user = getUserForToken(jwt);
  const [planningItems, setPlanningItems] = useState(planning || []);

  const sendLateArrivalMail = (item) => {
    Swal.fire({
      title: 'Aankomst mail',
      text: 'Wil je een mail verzenden naar de chauffeur met info over zijn laattijdige aankomst?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#2de385',
      confirmButtonText: 'Ja stuur',
      cancelButtonText: 'Annuleer',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetch(`${oneofficeAppURI}maillatearrival`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: item._id,
          }),
        });
        const data = await res.json();
        if (data?.success) {
          let newPlItems = [...planningItems];
          newPlItems.forEach((pItem) => {
            if (pItem._id === item._id) {
              pItem.lisMailLateArrival = Date.now();
            }
          });
          setPlanningItems(newPlItems);
          return toast.success(
            `Mail verstuurd naar ${item.driver?.alias || 'chauffeur'}`
          );
        } else {
          return toast.error('Probleem bij versturen');
        }
      }
    });
  };

  const sendWrongTimes = (item) => {
    Swal.fire({
      title: 'Foutieve tijden mail',
      text: 'Wil je een mail verzenden naar de chauffeur met info over zijn foutieve tijden?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#2de385',
      confirmButtonText: 'Ja stuur',
      cancelButtonText: 'Annuleer',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await fetch(`${oneofficeAppURI}mailwrongtimes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: item._id,
          }),
        });
        const data = await res.json();
        if (data?.success) {
          let newPlItems = [...planningItems];
          newPlItems.forEach((pItem) => {
            if (pItem._id === item._id) {
              pItem.lisMailWrongTimes = Date.now();
            }
          });
          setPlanningItems(newPlItems);
          return toast.success(
            `Mail verstuurd naar ${item.driver?.alias || 'chauffeur'}`
          );
        } else {
          return toast.error('Probleem bij versturen');
        }
      }
    });
  };

  useEffect(() => {
    setPlanningItems(planning);
  }, [planning]);

  return (
    <table
      className={`text-xs border mt-4 apptable ${
        inverse ? 'text-white' : 'text-black'
      }`}
      width="100%"
    >
      <thead>
        <tr className="font-bold bg-gray-300">
          <td>Datum</td>
          <td>Gemaakt</td>
          <td>Ophaal</td>
          <td>Opdracht</td>
          <td>Order</td>
          <td>Plaat</td>
          <td>Chauffeur</td>
          <td>GSM</td>
          <td>Route</td>
          <td>Aanvaard</td>
          <td>Gestart</td>
          <td>Aankomst</td>
          <td>SMS</td>
          <td>Instap</td>
          <td>Uitstap</td>
          <td>Afgerond</td>
          <td>Update</td>
        </tr>
      </thead>
      <tbody>
        {planningItems?.map((planItem, p) => (
          <tr
            key={p}
            className={`${planItem.usePartner ? 'bg-yellow-300' : ''}`}
          >
            <td>
              {planItem.execDateUTC
                ? format(planItem.execDateUTC, 'EEEE dd/MM')
                : 'Geen datum'}
            </td>
            <td>
              {planItem.created
                ? format(planItem.created, 'dd/MM HH:mm')
                : 'Geen datum'}
            </td>
            <td>
              {planItem.route?.firstPickupTime
                ? format(planItem.route.firstPickupTime, 'HH:mm')
                : 'Geen ophaaluur'}
            </td>
            <td>{planItem.lisData?.LisOpdrachtId}</td>
            <td>{planItem.ordernr}</td>
            <td>
              <span>
                {planItem.vehicle?.licensePlate &&
                planItem.vehicle?.licensePlate !== 'INGEVEN' ? (
                  <img
                    src={`/img/${
                      parseInt(planItem.vehicle?.model?.category?.seats || 0) <=
                      3
                        ? 'car'
                        : 'bus'
                    }.png`}
                    width="20"
                    height="8"
                    alt={planItem.vehicle?.licensePlate}
                  />
                ) : null}
                &nbsp;
                {planItem.usePartner
                  ? ''
                  : `${planItem.vehicle?.licensePlate}${
                      planItem.vehicle?.licensePlate !== 'INGEVEN'
                        ? `(${
                            planItem.vehicle?.model?.category?.seats || '?'
                          }p)`
                        : ''
                    }`}
              </span>
            </td>
            <td>
              {planItem.usePartner
                ? planItem.partner?.name || ''
                : planItem.driver?.alias}
            </td>
            <td>{planItem.usePartner ? '' : planItem.driver?.gsmWork}</td>
            <td>
              {planItem.route?.routeString?.full || 'Geen route'}{' '}
              {planItem.route?.routeString?.full ? (
                <a
                  href={`https://www.google.com/maps/dir/${createAddressForNavigation(
                    planItem.route.places[0]?.address
                  )}/${createAddressForNavigation(
                    planItem.route.places[1]?.address
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MapPinIcon className="w-4 h-4 text-green-400" />
                </a>
              ) : null}
            </td>
            <td>
              {planItem.lisConfirmed ? (
                <span className="flex flex-row items-center">
                  <CheckCircleIcon className="w-4 h-4 text-green-400 mr-1" />{' '}
                  {format(planItem.lisConfirmedDate, 'HH:mm')}
                </span>
              ) : (
                ''
              )}
            </td>
            <td
              style={{
                backgroundColor: !planItem.partner
                  ? !planItem.driverStarted
                    ? planItem.route.firstPickupTime < Date.now()
                      ? 'red'
                      : 'inherit'
                    : planItem.driverStarted > planItem.route.firstPickupTime
                    ? 'red'
                    : 'inherit'
                  : 'inherit',
              }}
            >
              {planItem.driverStarted ? (
                <span className="flex flex-row items-center">
                  <TruckIcon className="w-4 h-4 text-green-400 mr-1" />{' '}
                  {format(planItem.driverStarted, 'HH:mm')}
                </span>
              ) : (
                ''
              )}
              {planItem.geoFenceStartedTime ? (
                <span className="inline-block mt-1 rounded-lg bg-black text-white  p-1 pl-2 pr-2">
                  {format(planItem.geoFenceStartedTime, 'HH:mm')}
                </span>
              ) : null}
            </td>
            <td
              style={{
                backgroundColor:
                  !planItem.partner &&
                  planItem.route.firstPickupTime - 15 * 60 * 1000 < Date.now()
                    ? !planItem.lisArrivedDate
                      ? 'red'
                      : planItem.lisArrivedDate >
                        planItem.route.firstPickupTime - 15 * 60 * 1000
                      ? 'red'
                      : 'inherit'
                    : 'inherit',
              }}
            >
              {planItem.lisArrived ? (
                <span className="flex flex-row items-center">
                  <MapPinIcon className="w-4 h-4 text-green-400 mr-1 inline" />{' '}
                  {format(planItem.lisArrivedDate, 'HH:mm')}
                </span>
              ) : (
                ''
              )}
              {planItem.geoFenceArrivedTime ? (
                <span className="inline-block mt-1 rounded-lg bg-black text-white  p-1 pl-2 pr-2">
                  {format(planItem.geoFenceArrivedTime, 'HH:mm')}
                </span>
              ) : null}
              {user.role === 'gorsele' &&
              ((!planItem.partner &&
                planItem.route.firstPickupTime - 15 * 60 * 1000 < Date.now() &&
                !planItem.lisArrivedDate) ||
                planItem.lisArrivedDate >
                  planItem.route.firstPickupTime - 15 * 60 * 1000) ? (
                <span
                  className="cursor-pointer bg-black p-1 rounded-xl inline-block mt-1 text-white"
                  onClick={() => sendLateArrivalMail(planItem)}
                >
                  <EnvelopeIcon className="w-4 h-4 text-red-500 inline" />
                  {planItem.lisMailLateArrival
                    ? ` ${format(planItem.lisMailLateArrival, 'dd/MM')}`
                    : ''}
                </span>
              ) : null}
            </td>
            <td>
              {planItem.passengerSMS ? (
                <span className="flex flex-row items-center">
                  <DevicePhoneMobileIcon className="w-4 h-4 mr-1 text-green-400" />{' '}
                  {format(planItem.passengerSMSDate, 'HH:mm')}
                </span>
              ) : (
                ''
              )}
            </td>
            <td
              style={{
                backgroundColor: 'inherit',
              }}
            >
              {planItem.lisPassengerIn ? (
                <span className="flex flex-row items-center text-green-400 ">
                  <UserPlusIcon className="w-4 h-4 mr-1 " />{' '}
                  {format(
                    planItem.lisPassengerIn.time + 2 * 60 * 1000,
                    'HH:mm'
                  )}
                </span>
              ) : (
                ''
              )}
              {planItem.geoFencePassengerInTime ? (
                <span className="inline-block mt-1 rounded-lg bg-black text-white  p-1 pl-2 pr-2">
                  {format(planItem.geoFencePassengerInTime, 'HH:mm')}
                </span>
              ) : null}
            </td>
            <td>
              {planItem.lisPassengerUit ? (
                <span className="flex flex-row items-center text-red-400">
                  <UserMinusIcon className="w-4 h-4 mr-1" />{' '}
                  {format(
                    planItem.lisPassengerUit.time + 2 * 60 * 1000,
                    'HH:mm'
                  )}
                </span>
              ) : (
                ''
              )}
              {planItem.geoFencePassengerOutTime ? (
                <span className="inline-block mt-1 rounded-lg bg-black text-white p-1 pl-2 pr-2">
                  {format(planItem.geoFencePassengerOutTime, 'HH:mm')}
                </span>
              ) : null}
              {user.role === 'gorsele' &&
              planItem.executed &&
              (!planItem.lisPassengerUit ||
                (planItem.lisPassengerIn &&
                  planItem.lisPassengerUit &&
                  planItem.lisPassengerUit.time - planItem.lisPassengerIn.time <
                    5 * 60 * 1000)) ? (
                <span
                  className="cursor-pointer bg-black p-1 rounded-xl inline-block mt-1 text-white"
                  onClick={() => sendWrongTimes(planItem)}
                >
                  <EnvelopeIcon className="w-4 h-4 text-red-500 inline" />
                  {planItem.lisMailWrongTimes
                    ? ` ${format(planItem.lisMailWrongTimes, 'dd/MM')}`
                    : ''}
                </span>
              ) : null}
            </td>
            <td>
              {planItem.executed ? (
                <span className="flex flex-row items-center">
                  <CheckCircleIcon className="w-4 h-4 text-green-400 mr-1" />{' '}
                </span>
              ) : (
                ''
              )}
            </td>
            <td>
              {planItem.lisData && planItem.lisData?.LisLaatsteWijzigingRealUTC
                ? format(
                    new Date(
                      planItem.lisData?.LisLaatsteWijzigingRealUTC ||
                        planItem.lisData?.LisLaatsteWijziging
                    ),
                    'dd/MM HH:mm'
                  )
                : ''}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PlanningList;
