/* eslint-disable react-hooks/exhaustive-deps */
import { Bars4Icon, PlusIcon } from '@heroicons/react/24/solid';
import {
  ArrowPathIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { useSideBar } from '../hooks/useSideBar';
import { useAuth } from '../hooks/useAuth';
import { useRecentItems } from '../hooks/useRecentItems';
import { getUserForToken } from '../token';
import { getInitials } from '../utils/UserUtil';
import SearchBar from './SearchBar';
import { useSearch } from '../hooks/useSearch';
import { useEffect, useState } from 'react';
import TicketForm from '../views/TicketForm';
import { useNavigate } from 'react-router-dom';
import MessageForm from '../views/MessageForm';
import { format } from 'date-fns';

const AppHeader = () => {
  const { sideBarOpen, toggleSidebar, showRightSideBar, hideRightSideBar } =
    useSideBar();
  const { recentItems, recentFetching, refreshRecentItems } = useRecentItems();
  const { jwt, logout } = useAuth();
  const user = jwt ? getUserForToken(jwt) : null;
  const { searchVisible } = useSearch();
  const [createVisible, setCreateVisible] = useState(false);
  const [recentVisible, setRecentVisible] = useState(false);
  const [orderSearch, setOrderSearch] = useState();
  const navigate = useNavigate();

  const openTicket = (id) => {
    setCreateVisible(false);
    showRightSideBar(
      <TicketForm
        id={id}
        close={(reload) => {
          hideRightSideBar();
          if (!id) {
            navigate('/tickets');
          }
        }}
        forceReload={() => {
          navigate('/tickets');
        }}
      />,
      true
    );
  };

  const openMessage = () => {
    setCreateVisible(false);
    showRightSideBar(
      <MessageForm
        close={(reload) => {
          hideRightSideBar();
          navigate('/messages');
        }}
      />,
      true
    );
  };

  const openRecentItem = (item) => {
    setCreateVisible(false);
    setRecentVisible(false);
    switch (item.type) {
      case 'order':
        setOrderSearch(item.typeSearch.toString());
        break;
      case 'message':
        navigate('/messages');
        break;
      case 'ticket':
        openTicket(item.typeSearch);
        break;
      default:
        break;
    }
  };

  const showRecentItems = () => {
    refreshRecentItems();
    setRecentVisible(true);
  };

  return (
    <div className="header">
      {!searchVisible ? (
        <div className="flex flex-row">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md text-white hover:scale-110 transition-all focus:outline-none"
            onClick={toggleSidebar}
          >
            <Bars4Icon className="text-white w-6" />
          </button>
          {createVisible || recentVisible ? (
            <div
              className="w-[100vw] h-[100vh] fixed top-0 left-0 bg-black opacity-50 z-[990]"
              onClick={() => {
                setCreateVisible(false);
                setRecentVisible(false);
              }}
            />
          ) : null}
          <div className="create group">
            <div
              className="btn"
              onClick={() => setCreateVisible(!createVisible)}
            >
              <span className="flex flex-row items-center">
                <span className="w-6 h-6 rounded-3xl bg-green-400 flex justify-center items-center mr-2">
                  <PlusIcon className="h-4 w-4 text-white" />
                </span>{' '}
                Nieuw
              </span>
            </div>
            <div className={`pop ${createVisible ? '!flex' : ''}`}>
              <button onClick={() => openTicket()}>
                <ChatBubbleOvalLeftIcon className="w-4 h-4" /> Ticket
              </button>
              <button onClick={() => openMessage()}>
                <DevicePhoneMobileIcon className="w-4 h-4" /> Bericht
              </button>
            </div>
            <div className="avatar-menu"></div>
          </div>
          <div className="recent group">
            <div className="btn" onClick={() => showRecentItems()}>
              <span className="flex flex-row items-center">
                <span className="w-6 h-6 rounded-3xl flex justify-center items-center mr-1">
                  {recentFetching ? (
                    <ArrowPathIcon className="h-4 w-4 animate-spin text-white" />
                  ) : (
                    <ClockIcon className="h-4 w-4 text-white" />
                  )}
                </span>{' '}
                Recent
              </span>
            </div>
            <div
              className={`pop ${
                recentVisible && !recentFetching ? '!flex' : ''
              }`}
            >
              {recentItems?.length
                ? recentItems.map((recentItem, r) => (
                    <button onClick={() => openRecentItem(recentItem)} key={r}>
                      <span
                        className={`w-6 h-6 rounded-3xl flex justify-center items-center mr-2 ${
                          recentItem.type === 'order'
                            ? 'bg-green-400'
                            : recentItem.type === 'message'
                            ? 'bg-blue-400'
                            : 'bg-purple-400'
                        }`}
                      >
                        {recentItem.type === 'order' && (
                          <QueueListIcon className="w-4 h-4 text-white" />
                        )}
                        {recentItem.type === 'message' && (
                          <DevicePhoneMobileIcon className="w-4 h-4 text-white" />
                        )}
                        {recentItem.type === 'ticket' && (
                          <ChatBubbleOvalLeftIcon className="w-4 h-4 text-white" />
                        )}
                      </span>
                      <span className="text-xs">
                        <span className="font-bold text-left inline-block w-[80px]">
                          {format(recentItem.created, 'dd/MM HH:mm')}
                        </span>
                        <span>{recentItem.text}</span>
                      </span>
                    </button>
                  ))
                : null}
            </div>
            <div className="avatar-menu"></div>
          </div>
        </div>
      ) : null}
      <SearchBar forceSearch={orderSearch} />
      {user && !searchVisible ? (
        <div className="user group">
          <div className="avatar">
            {getInitials(user.firstName, user.lastName)}
          </div>
          <div className="pop group-hover:block">
            <span className="name">{user.fullName}</span>
            <br />
            <span>
              <strong>{user.email}</strong>
            </span>
            <br />
            <button
              className="border-[1px] border-red-500 p-1 w-full rounded-lg hover:bg-red-500 hover:text-black mt-2"
              onClick={() => logout()}
            >
              Afmelden
            </button>
          </div>
          <div className="avatar-menu"></div>
        </div>
      ) : null}
    </div>
  );
};

export default AppHeader;
